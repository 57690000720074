import React from 'react';
import { TransitionGroup, Transition as ReactTransition } from 'react-transition-group';
import { Colors } from './Styles';

// The duration for each phase of the transition
// So the total duration will be _twice_ this
const timeout = 300;

const styles = {
    entering: {
        position: 'absolute',
        opacity: 1,
    },
    entered: {
        transition: `opacity ${timeout}ms ease-in-out`,
        opacity: 1,
    },
    exiting: {
        transition: `opacity ${timeout}ms ease-in-out`,
        opacity: 1
    },
};

const Transition = ({ children, location }) => (
    <div>
        {children}
    </div>
);

export default Transition;

    // <TransitionGroup>
    //     <ReactTransition key={window.location.href} timeout={timeout}>
    //         {(status) => (
    //             <div style={{ backgroundColor: Colors.purple }}>
    //                 <div style={styles[status]}>
    // { children }
    //                 </div>
    //             </div>
    //         )}
    //     </ReactTransition>
    // </TransitionGroup>