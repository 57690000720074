import * as React from "react"
import { slide as Menu } from 'react-burger-menu'

import "./mobileMenu.css"

export const MobileMenu = () => {

    return (
        <Menu>
            <a id="home" className="menu-item" href="/">Home</a>
            <a id="about" className="menu-item" href="/About">About</a>
            <a id="services" className="menu-item" href="/Services">Services</a>
            <a id="portfolio" className="menu-item" href="/Portfolio">Portfolio</a>
            <a id="contact" className="menu-item" href="/Contact">Contact</a>
            <a id="portal" className="menu-item" href="https://sparklecelebrations.hbportal.co/app/login" target="_blank">Client Portal</a>
        </Menu>
    );
}