export const Colors = {
    pink: "#ffc1cc",
    grey: "#91a9b7",
    purple: "#420039",
    lightPurple: "#6f0160",
    white: "#ffffff",
    cream: "#fcf6ed",
    slate: "#50514f"
}

export const Padding = {
    gutter: "5%",
    banner: "3%",
    mobileGutter: "3%"
}