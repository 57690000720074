import * as React from "react"
import { SocialIcon } from 'react-social-icons'
import { PPEButton } from "./PPEButton"
import { Colors } from "./Styles"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { useEffect, useState } from 'react';

export const Footer = () => {
    const [isDesktop, setDesktop] = useState(null)
    useEffect(() => {
        if (typeof window !== 'undefined') {
            setDesktop(window.innerWidth > 920)
        }
    }, [])

    const updateMedia = () => {
        setDesktop(window.innerWidth > 920)
    }

    useEffect(() => {
        window.addEventListener("resize", updateMedia)
        return () => window.removeEventListener("resize", updateMedia)
    })

    if (isDesktop !== null && isDesktop === true) {
        return (
            <footer
                style={{
                    display: `flex`,
                    justifyContent: `space-between`,
                    background: Colors.pink,
                    bottom: 0,
                    position: 'absolute',
                    width: '100%',
                    height: "5.5rem",
                    padding: '20px'
                }}
            >
                <div style={{ display: `inline-flex`, justifyContent: `space-between`, width: `100%` }}>
                    <div>
                        <Link to="/" >
                            <StaticImage
                                src="../images/Logos/logoFull.png"
                                loading="eager"
                                quality={95}
                                height={60}
                                formats={["auto", "webp", "avif"]}
                                alt="Sparkle Celebrations Logo"
                                style={{ margin: 0 }} />
                        </Link>
                    </div>
                    <div>
                        <div className="icon" style={{ display: 'flex', justifyContent: 'center' }}>
                            <SocialIcon style={{ height: `30px`, width: `30px`, marginRight: `15px` }} fgColor={Colors.white} bgColor={Colors.purple} target="_blank" url="https://www.facebook.com/ThePartyPlanEvent/" />
                            <SocialIcon style={{ height: `30px`, width: `30px` }} fgColor={Colors.white} bgColor={Colors.purple} target="_blank" url="https://www.instagram.com/sparkle.celebrations/" />
                        </div>
                        <p style={{ margin: 0 }}>© {new Date().getFullYear()} by Sparkle Celebrations.</p>
                    </div>
                    <PPEButton buttonText="Contact" link="/Contact" />
                </div>
            </footer>
        )
    }
    else if (isDesktop !== null && isDesktop === false) return (
        <footer
            style={{
                display: `flex`,
                justifyContent: `space-between`,
                background: Colors.pink,
                bottom: 0,
                position: 'absolute',
                width: '100%',
                height: "5.5rem",
                padding: '20px'
            }}
        >
            <div style={{ display: `inline-flex`, justifyContent: `space-between`, width: `100%` }}>
                <div>
                    <div className="icon" style={{ display: 'flex', justifyContent: 'center' }}>
                        <SocialIcon style={{ height: `30px`, width: `30px`, marginRight: `15px` }} fgColor={Colors.white} bgColor={Colors.purple} target="_blank" url="https://www.facebook.com/ThePartyPlanEvent/" />
                        <SocialIcon style={{ height: `30px`, width: `30px` }} fgColor={Colors.white} bgColor={Colors.purple} target="_blank" url="https://www.instagram.com/sparkle.celebrations/" />
                    </div>
                    <p style={{ margin: 0 }}>© {new Date().getFullYear()} by Sparkle Celebrations.</p>
                </div>
                <PPEButton mobile buttonText="Contact" link="/Contact" />
            </div>
        </footer >
    )
}