import * as React from "react"
import { Colors } from "./Styles"
import { Button } from "@mui/material"
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Link } from "gatsby";
import styled from "styled-components";

const SpecialButton = styled.p`
    color: ${Colors.white};
    font-size: 20px;
`

const ContactButton = styled.p`
    color: ${Colors.white};
    font-size: 26px;
`

const ContactButtonMobile = styled.p`
    color: ${Colors.white};
    font-size: 18px;
`

const buttonTheme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '1.5rem',
                    backgroundColor: Colors.purple,
                    textTransform: 'none',
                    borderRadius: '30px',
                    width: '150px',
                    lineHeight: '0px',
                    '&:hover': {
                        backgroundColor: Colors.lightPurple,
                        transition: "0.3s ease-out"
                    },
                },
            },
        },
    },
});

const mobileButtonTheme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                    backgroundColor: Colors.purple,
                    textTransform: 'none',
                    borderRadius: '30px',
                    width: '125px',
                    lineHeight: '0px',
                    '&:hover': {
                        backgroundColor: Colors.lightPurple,
                        transition: "0.3s ease-out"
                    },
                },
            },
        },
    },
});


export const PPEButton = ({ wide, clientPortal, buttonText, link, centered, mobile, externalLink }) => {

    let linkDirection = null;
    if (externalLink) {
        if (clientPortal) {
            linkDirection = (
                <Button style={{ marginLeft: "20px", height: "70px", width: "100px" }} variant="contained">
                    <a href={externalLink} target="_blank" style={{ textDecoration: "none" }}>
                        <SpecialButton>Client <p> Portal </p> </SpecialButton>
                    </a>
                </Button>)
        }
        else {
            if (!mobile) {
                linkDirection = (
                    <Button style={{ width: "220px" }} variant="contained">
                        <a href={externalLink} target="_blank" style={{ textDecoration: "none" }}>
                            <ContactButton>{buttonText}</ContactButton>
                        </a>
                    </Button>)
            }
            else {
                linkDirection = (
                    <Button style={{ width: "220px" }} variant="contained">
                        <a href={externalLink} target="_blank" style={{ textDecoration: "none" }}>
                            <ContactButtonMobile>{buttonText}</ContactButtonMobile>
                        </a>
                    </Button>)
            }
        }
    }
    else {
        linkDirection = (<Button variant="contained"><Link to={link} style={{ textDecoration: "none", color: Colors.white }}><p>{buttonText}</p></Link></Button>)
    }
    if (mobile && centered) {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <ThemeProvider theme={mobileButtonTheme}>
                    {linkDirection}
                </ThemeProvider>
            </div >
        )
    }
    if (mobile && !centered) {
        return (
            <ThemeProvider theme={mobileButtonTheme}>
                {linkDirection}
            </ThemeProvider>
        )
    }
    if (!centered) {
        return (
            <ThemeProvider theme={buttonTheme}>
                {linkDirection}
            </ThemeProvider>
        )
    }
    if (wide) {
        linkDirection = (
            <Button style={{ width: "220px" }} variant="contained">
                <a href={externalLink} target="_blank" style={{ textDecoration: "none" }}>
                    <ContactButton>{buttonText}</ContactButton>
                </a>
            </Button>)
    }
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <ThemeProvider theme={buttonTheme}>
                {linkDirection}
            </ThemeProvider>
        </div>
    )
}

