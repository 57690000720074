import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Transition from "./Transition"

import { Header } from "./header"
import { Footer } from "./footer"
import "./layout.css"
import "../../node_modules/animate.css/animate.min.css";


const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Transition location={location}>
      <div style={{
        position: 'relative',
        minHeight: '100vh',
      }}>
        <Header />
        <div
          style={{
            margin: `0 auto`,
            paddingBottom: "5.5rem"
          }}
        >
          <main>{children}</main>
        </div>
        <Footer />
      </div>
    </Transition>
  )



}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
