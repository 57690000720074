import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Colors } from "./Styles"
import { useEffect, useState } from 'react';
import { MobileMenu } from "./MobileMenu";
import { PPEButton } from "./PPEButton";

const HeaderLink = ({ linkText, linkUrl }) => {
  return (
    <Link
      partiallyActive
      activeStyle={{
        color: Colors.purple,
        fontWeight: 700
      }}
      to={linkUrl} style={{ textDecoration: `none`, color: Colors.slate, paddingLeft: `30px`, fontSize: '20px', fontFamily: 'Corben' }}>{linkText}</Link>
  )
}

const HomeLink = ({ linkText, linkUrl }) => {
  return (
    <Link
      activeStyle={{
        color: Colors.purple,
        fontWeight: 700
      }}
      to={linkUrl} style={{ textDecoration: `none`, color: Colors.slate, paddingLeft: `30px`, fontSize: '20px', fontFamily: 'Corben' }}>{linkText}</Link>
  )
}

export const Header = () => {
  const [isDesktop, setDesktop] = useState(null)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 920)
    }
  }, [])

  const updateMedia = () => {
    setDesktop(window.innerWidth > 920)
  }

  useEffect(() => {
    window.addEventListener("resize", updateMedia)
    return () => window.removeEventListener("resize", updateMedia)
  })

  if (isDesktop === true && isDesktop !== null) {
    return (<header
      style={{
        margin: `0 auto`,
        paddingLeft: '15px',
        paddingRight: '15px',
        display: `flex`,
        alignItems: `center`,
        justifyContent: `space-between`,
        background: Colors.pink,
      }}
    >
      <Link to="/" >
        <StaticImage
          src="../images/Logos/logoFull.png"
          loading="eager"
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt="Sparkle Celebrations Logo" />
      </Link>
      <div>
        <HomeLink linkText="Home" linkUrl="/" />
        <HeaderLink linkText="About" linkUrl="/About" />
        <HeaderLink linkText="Services" linkUrl="/Services" />
        <HeaderLink linkText="Portfolio" linkUrl="/Portfolio" />
        <HeaderLink linkText="Contact" linkUrl="/Contact" />
        <PPEButton clientPortal externalLink="https://sparklecelebrations.hbportal.co/app/login" />
      </div>
    </header>)
  }
  else if (isDesktop !== null && isDesktop === false) return (
    <div>
      <MobileMenu />
      <div style={{ position: 'fixed', width: "100%", alignItems: "center", backgroundColor: Colors.pink, height: "95px", display: "flex", justifyContent: "flex-end", borderBottom: "1px solid #420039", zIndex: '100' }}>
        <Link to="/">
          <StaticImage
            src="../images/Logos/logoFull.png"
            loading="eager"
            quality={95}
            height={75}
            formats={["auto", "webp", "avif"]}
            alt="Sparkle Celebrations Logo" />
        </Link>
      </div>
    </div>
  )
}
